import React, { useState } from "react";
import { Link } from "react-router-dom";

import Popup from "../../components/Modals/Popup";
import ClientSignUpForm from "../Forms/ClientSignUpForm";
import * as userService from "../../services/userService";
import Notification from "../../components/Modals/Notification";

export default function NavBar() {
  const [navbar, setNavbar] = useState(false);
  //
  const [openPopup, setOpenPopup] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const signUp = (data) => {
    data.autorisation = "ROLE_CLIENT";
    userService.signup(data).then((res) => {
      setOpenPopup(false);
      setNotify({
        isOpen: true,
        message:
          "Account Created Successfully, we will contact you for more details in the shortest time...",
        type: "info",
      });
    });
  };

  //

  return (
    <nav className="w-full bg-green-700 text-white shadow sticky top-0 h-20">
      <div className="justify-between px-4 mx-auto lg:max-w-7xl md:items-center md:flex md:px-8">
        <div>
          <div className="flex items-center justify-between py-3 md:py-5 md:block font-bold text-2xl">
            <img src="../assets/EARM_LogoTypeWhite.svg" alt="" width={200} />
            <div className="md:hidden">
              <button
                className="p-2 text-gray-700 rounded-md outline-none focus:border-gray-400 focus:border"
                onClick={() => setNavbar(!navbar)}
              >
                {navbar ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>
        <div>
          <div
            className={`flex-1 justify-self-center pb-3 mt-8 md:block md:pb-0 md:mt-0 ${
              navbar ? "block" : "hidden"
            }`}
          >
            <ul className="items-center justify-between my-6 space-y-8 md:flex md:space-x-6 md:space-y-0 ">
              <li className="text-white font-bold hover:text-slate-50">
                <Link to={"/"}>Home</Link>
              </li>
              <li className="text-white font-bold hover:text-slate-50">
                <Link to="/blogs">Blogs</Link>
              </li>
              <li className="text-white font-bold hover:text-slate-50">
                <Link to="/ourservices">Services</Link>
              </li>
              <li className="text-white font-bold hover:text-slate-50">
                <Link to={"/about"}>About US</Link>
              </li>
              <li className="text-white font-bold hover:text-slate-50">
                <Link to="/contact">Contact US</Link>
              </li>
              <li className="text-white font-bold hover:text-slate-50">
                <Link to="/gallery">Gallery</Link>
              </li>
              <li className="text-white font-bold hover:text-slate-50 ">
                <Link to="/login">Log In</Link>
              </li>
              <li className="text-white font-bold hover:text-slate-50">
                <Link
                  onClick={() => {
                    setOpenPopup(true);
                  }}
                >
                  Sign Up
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Popup
        title=<h1 className="text-2xl">Client Registration</h1>
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <ClientSignUpForm signUp={signUp} />
      </Popup>
      <Notification notify={notify} setNotify={setNotify} />
    </nav>
  );
}
